import React, { useState, useEffect } from 'react';

import Styles, { Fab, LineMenuHome } from '../components/Styles'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../atoms/user.atom'
import navigationState from '../atoms/navigate_state.atom'

import LeftSideBar from '../components/SideBars/LeftSideBar'
import CustomerList from '../components/page_contents/CustomerList'

import FormDataTickets from '../components/Forms/FormDataTickets'
import ListTickets from '../components/Tables/Tickets'
import TicketDetail from '../components/TicketDetail'

import TopTickets from '../components/page_contents/TopTickets'
import SuportCalendar from '../components/page_contents/SuportCalendar';
import MonitoramentoNoturno from '../components/monitoramento_noturno/MonitoramentoNoturno';
import MonitoramentoOpts from '../components/MonitoramentoOpts';
import Outages from '../components/outages/Outages';
import OutagesDashboard from '../components/outages_dashboard/Outages.js'

import CadUser from './ListUsers.js';

import HomePosts from '../components/HomePosts';
import TicketsCGR from '../components/TicketsCGR';
import Reports from '../components/Reports';
import SgiTools from '../components/SgiTools';
import WarningScale from '../components/WarningScale';
// import MapFlow from '../components/MapFlow';

import ViteModal from '../components/ViteModal'

function Home_N(props) {
	const styles = Styles();

	const profile_user = useRecoilValue(profileData)

	const [navigation_state, set_navigation_state] = useRecoilState(navigationState)

	const [device_height, set_device_height] = useState(null)

	useEffect(() => {
		// const dw = document.documentElement.clientWidth
		const dh = document.documentElement.clientHeight
		// set_device_width(dw)
		set_device_height(dh)
		// set_navigation_state({page_content: 'home'})

	}, [])


	return (
		<div className="flex flexRow height_less_header" style={{
			width: "100%",
			alignItems: "flex-start"
		}}>
			<ViteModal />
			<LeftSideBar />

			{navigation_state?.page_content && Object.keys(profile_user).length > 0 &&
				(() => {
					switch (navigation_state.page_content) {
						case 'list_clients':
							return (<CustomerList />)
							break;
						case 'list_users':
							return (<CadUser />)
						case 'tickets_cgr':
							return (<TicketsCGR />)
							break;
						case 'formtickets':
							return (<FormDataTickets />)
							break;
						case 'ticket_detail':
							return (<TicketDetail />)
							break;
						case 'listtickets':
							return (<ListTickets />)
							break
						case 'suport_calendar':
							return (<SuportCalendar />)
							break;
						case 'reports':
							return (<Reports />)
							break;
						case 'toptickets':
							return (<TopTickets />)
							break;
						case 'home':
							return profile_user.nivel.match(/Outage-user|Outage-level-1/i) ? <OutagesDashboard />  : profile_user.nivel.match(/técnico|super/i) ? <HomePosts /> : <CustomerList />
							break;
						case 'monitoramento':
							return (<MonitoramentoOpts />)
							break;
						case 'tools':
							return (<SgiTools />)
							break;
						case 'monitoramento_noturno':
							return (<MonitoramentoNoturno />)
							break;
						case 'outages_adm':
							return (<Outages />)
							break;
						case 'warningscale':
							return (<WarningScale />)
						case 'mapflowtest':
							// return (<MapFlow />)
							return (<></>)
							break;
						default:
							return profile_user.nivel === 'Outage-user' ? <OutagesDashboard />  : profile_user.nivel.match(/técnico|super/i) ? <HomePosts /> : <CustomerList />
							break;
					}
				})()
			}
		</div >

	);
}

export default Home_N;


