import React from 'react';

export const copyToClipBoard = async (_text, _pop = false) => {
    try {
        await navigator.clipboard.writeText(_text);
        if (_pop) {
            alert('Copiado!');
        }
    } catch (err) {
        console.log(err)
        alert('Falha ao copiar.');
    }
};


const HidePass = (props) => {
    const { _pass, _txt, _style } = props
    return (
        <div
            style={_style ? _style : {}}
            onClick={() => {
                if (_pass) {
                    copyToClipBoard(_pass)
                }
            }}
        >
            <b>{_txt ? _txt : ''} </b>{_pass.replaceAll(/[^ ]/g, '*')}
        </div>
    )
}

export const msToDateTime = (ms) => {
    const d = new Date(ms)
    const dia_s = {
        0: 'domingo',
        1: 'segunda',
        2: 'terça',
        3: 'quarta',
        4: 'quinta',
        5: 'sexta',
        6: 'sábado'
    }
    const ret = {
        n_dia_semana: d.getDay(), // dia 0 = domingo
        dia_semana: dia_s[d.getDay()],
        data: `${d.getDate() < 10 ? '0' : ''}${d.getDate()}`,
        mes: `${(d.getMonth() + 1) < 10 ? '0' : ''}${d.getMonth() + 1}`,
        ano: d.getFullYear(),
        hora: d.getHours(),
        minuto: d.getMinutes(),
        segundos: d.getSeconds(),
        iso_i: `${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? '0' : ''}${d.getMonth() + 1}-${d.getDate() < 10 ? '0' : ''}${d.getDate()} ${d.getHours() < 10 ? '0' : ''}${d.getHours()}:${d.getMinutes() < 10 ? '0' : ''}${d.getMinutes()}:${d.getSeconds() < 10 ? '0' : ''}${d.getSeconds()}`,
        iso2: d.toISOString()
    }
    return ret
}

export const msToTime = (ms) => {
    if (ms > 0) {
        var hours = ms / (1000 * 60 * 60);
        var absoluteHours = Math.floor(hours);
        var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

        //Get remainder from hours and convert to minutes
        var minutes = (hours - absoluteHours) * 60;
        var absoluteMinutes = Math.floor(minutes);
        var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

        //Get remainder from minutes and convert to seconds
        var seconds = (minutes - absoluteMinutes) * 60;
        var absoluteSeconds = Math.floor(seconds);
        var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;
        return ({
            horas: h,
            minutos: m,
            segundos: s
        })
    } else {
        return ({
            horas: '00',
            minutos: '00',
            segundos: '00'
        })
    }

}

export const groupArrObjByKey = (_data, _key) => {
    const temp = _data.reduce(function (r, a) {
        r[a[_key]] = r[a[_key]] || [];
        r[a[_key]].push(a);
        return r;
    }, Object.create(null))

    return temp
}

export const getTimeZone = (date) => {
    const temp = new Date(new Date(date).getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('.')[0]
    return temp
}

export const randomString = (length) => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export default HidePass;