import React, { useEffect, useState } from 'react';
import Modal2 from '../Modal2';
import { api_bd_nr } from '../../services/Api';
import { Send, Unlock } from 'lucide-react'

import { profileData } from '../../atoms/user.atom';
import { useRecoilValue } from 'recoil';

function SmsAuth({ showComponent, setShowComponent, setAuth, customer_id }) {
    const userProfile = useRecoilValue(profileData)
    const [verificationCode, setVerificationCode] = useState('')
    const [authData, setAuthData] = useState({
        customer_id: "",
        month: "",
        year: 0,
        cellNumbers: []
    })

    const sendSMSToCustomer = () => {
        api_bd_nr.post('/authtreatment', { customer_id })
            .then(resp => {
                setAuthData({
                    customer_id: resp.data.customer_id,
                    month: resp.data.month,
                    year: parseInt(resp.data.year),
                    cellNumbers: resp.data.cellNumbers
                })
            })
            .catch(error => { })
    }


    const adminUnlock = () => {
        api_bd_nr.post('/authtreatment', { customer_id, unlockAdmin: true })
            .then(resp => {
                setAuthData({
                    customer_id: resp.data.customer_id,
                    month: resp.data.month,
                    year: parseInt(resp.data.year),
                    cellNumbers: resp.data.cellNumbers
                })

                if (setAuth) {
                    setAuth(true)
                }
                setShowComponent(false)
            })
            .catch(error => { })
    }


    return (
        showComponent &&
        <Modal2
            callbackfn={() => {
                if (setShowComponent) {
                    setShowComponent(false)
                }
            }}
        >
            <div
                className='flex flex-col rounded bg-white p-20'
                style={{
                    display: 'flex',
                    width: '80%',
                    // minHeight: 500, 
                    justifySelf: 'flex-start',
                    alignItems: 'center'
                }}
            >
                {authData.customer_id === "" &&
                    <>
                        <p className='text-xl text-center'><span style={{ fontSize: '2em', marginRight: 10 }}>⚠️</span> O cliente esgotou o tempo contratado para o mês atual, deseja enviar um SMS para o cliente com um código de autorização para prosseguir com a trataiva</p>
                        <button
                            className={`w-[200px] flex flex-row items-center justify-center border border-intec-100 mt-5 rounded text-white p-2 bg-intec-500 hover:bg-intec-600`}
                            onClick={() => {
                                sendSMSToCustomer()
                            }}

                        >
                            <span>Enviar SMS</span>
                            <Send size={18} className='ml-4' />
                        </button>

                        {userProfile.nivel.match(/super/i) &&
                            <button
                                className={`w-[200px] flex flex-row items-center justify-center border border-intec-100 mt-8 rounded text-white p-2 bg-intec-500 hover:bg-intec-600`}
                                onClick={() => {
                                    adminUnlock()
                                }}

                            >
                                <span>Desbloquear (Admin)</span>
                                <Unlock size={18} className='ml-4' />
                            </button>
                        }
                    </>
                }
                {authData.customer_id !== "" &&
                    <>
                        <p className='text-xl'>SMS enviado para o cliente com sucesso para os seguinte(s) telefones:</p>
                        <p className='text-xl font-bold'>{authData.cellNumbers.join(', ')}</p>
                        <p className='text-xl mt-5'>Solicite ao cliente o codigo que foi enviado por SMS digite no campo abaixo e depois clique em verificar.</p>
                        <input
                            type="text"
                            value={verificationCode}
                            className='w-[200px] border border-intec-100 rounded mt-5 p-2 text-center font-bold text-2xl'
                            style={{ letterSpacing: 11 }}
                            maxLength={6}
                            onChange={(e) => {
                                setVerificationCode(e.target.value.toUpperCase())
                            }}
                        />
                        <button
                            className={`w-[200px] border border-intec-100 mt-5 rounded text-white p-2 ${verificationCode.length === 6 ? 'bg-intec-500 hover:bg-intec-600' : 'bg-intec-100'}`}
                            disabled={verificationCode.length < 6 ? true : false}
                            onClick={() => {
                                if (verificationCode.length > 0) {
                                    api_bd_nr.get(`/authtreatment?customer_id=${authData.customer_id}&authcode=${verificationCode}`)
                                        .then(async (resp) => {
                                            if (resp.data.length === 0) {
                                                alert('código inválido')
                                            } else {
                                                alert('código verificado com sucesso')
                                                if (setAuth) {
                                                    setAuth(true)
                                                }
                                                setShowComponent(false)
                                                // console.log(resp.data)
                                            }
                                        })
                                        .catch(error => { })
                                }
                                // if(setAuth){
                                //     setAuth(true)
                                // }
                            }}
                        >VERIFICAR</button>
                    </>
                }

            </div>
        </Modal2>
    );
}

export default SmsAuth;