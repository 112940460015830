import React, {
    useState,
    useEffect
} from 'react';

// import { makeStyles } from '@material-ui/core/styles';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import * as IoIcons from 'react-icons/io';

import Styles from '../Styles'

import { api_bd } from '../../services/Api'


import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import Autocomplete from '@material-ui/lab/Autocomplete';

import municipios from '../../assets/municipios_brasil.json';

import { useRecoilState } from 'recoil'
import clientDetailsData from '../../atoms/client_details.atom'

function FormEditAccess(props) {
    const { data_client, width } = props
    const [update_client_data, set_update_client_data] = useRecoilState(clientDetailsData)
    const styles = Styles();

    const [data_access, set_data_access] = useState(props.data_access)
    const [submit_is_disabled, set_submit_is_disabled] = useState(false)

    const [list_stations, set_list_stations] = useState([])

    const [type_access, set_type_access] = useState('')

    const lista_municipios = [...municipios]
    // const [lista_municipios, set_lista_municipios] = useState([])
    const [localidade_selecionada, set_localidade_selecionada] = useState({})


    const carregar_estacoes = async () => {
        const lista = await api_bd.get('/stations')
        const list = lista.data.filter(sta => sta.user === data_client._id).sort((a, b) => a.codigo_estacao.localeCompare(b.codigo_estacao))
        set_list_stations(list)
    }


    const handle_data_access = (key, value) => {
        let data = { ...data_access }
        data[key] = value
        set_data_access(data)
        if (key === 'tipo') {
            set_type_access(value)
        }
    }

    // const handle_lista_municipios = async (value) => {
    //     if (value !==  "") {
    //         const { data } = await api_bd.get(`/locations/${value}`)
    //         set_lista_municipios(data)
    //     }
    // }

    useEffect(() => {
        set_type_access(data_access.tipo)
    }, [data_access])



    useEffect(() => {
        (async () => {
            await carregar_estacoes()
        })()
    }, [])


    return (
        <div className={styles.div_root_component} style={{ width }}>
            <Card className={styles.card}>
                <CardContent>
                    <CardHeader
                        title="Editar Acesso"
                        subheader=""
                    />
                    {data_access.situacao === 'Inativo' &&
                        <div
                            className="flex"
                            style={{
                                width: 180,
                                cursor: "pointer",
                                marginBottom: 20
                            }}
                            onClick={async () => {
                                if (window.confirm(`Tem certeza que deseja excluir: ${data_access.descricao.toUpperCase()}`)) {
                                    try {
                                        const resp = await api_bd.delete(`/customers/deleteaccess/${data_client._id}/${data_access._id}`)
                                        props.callbackfn()
                                        set_update_client_data(resp.data)
                                    } catch (error) {
                                        if (error.message.indexOf('code 400') > -1) {
                                            alert("Verifique os dados e tente novamente.")
                                        }
                                    }
                                }
                            }}>
                            <span style={{ fontSize: '1.1em' }}>Excluir acesso </span>
                            <IoIcons.IoIosTrash
                                size={24}
                                style={{ marginRight: 10, marginTop: -2 }}
                            />
                        </div>
                    }
                    <form className={styles.form_cad_user} noValidate autoComplete="off">

                        <div style={{ width: "100%" }}>
                            <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                <InputLabel htmlFor="select-label">Tipo &nbsp;  *</InputLabel>
                                <Select
                                    defaultValue={data_access.tipo ? data_access.tipo : ""}
                                    labelId="select-label"
                                    id="select-nivel"
                                    // value={type_filter_access}
                                    onChange={e => {
                                        handle_data_access("tipo", e.target.value)
                                    }}
                                >
                                    <MenuItem value="Ativo de Rede">Ativo de Rede</MenuItem>
                                    <MenuItem value="Conta">Conta</MenuItem>
                                    <MenuItem value="Ferramenta">Ferramenta</MenuItem>
                                    <MenuItem value="Servidor">Servidor</MenuItem>
                                    <MenuItem value="VPN">VPN</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div style={{ width: "100%" }}>
                            <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                <InputLabel htmlFor="select-label">Situação &nbsp;  *</InputLabel>
                                <Select
                                    defaultValue={data_access.situacao ? data_access.situacao : ""}
                                    labelId="select-label"
                                    id="select-nivel"
                                    // value={type_filter_access}
                                    onChange={e => {
                                        handle_data_access("situacao", e.target.value)
                                    }}
                                >
                                    <MenuItem value="Ativo">Ativo</MenuItem>
                                    <MenuItem value="Inativo">Inativo</MenuItem>
                                </Select>
                            </FormControl>
                        </div>


                        {/* OCULTO */}
                        {/* {(type_access === 'VPN' || type_access === 'Ativo de Rede' || type_access === 'Conta' || type_access === 'Servidor' || type_access === 'Ferramenta') &&
                            <div style={{ width: "100%" }}>
                                <Autocomplete
                                    value={{ municipio_uf: data_access.localidade }}
                                    options={lista_municipios}
                                    getOptionLabel={(option) => `${option['municipio_uf']}`}
                                    id="localidade"
                                    autoComplete
                                    includeInputInList
                                    renderInput={(params) => <TextField {...params} label="Localidade" margin="normal" />}
                                    onChange={e => {
                                        handle_data_access("localidade", e.target.innerText ? e.target.innerText : "")
                                    }}
                                />
                            </div>
                        } */}

                        {(type_access === 'Ativo de Rede') &&
                            <div style={{ width: "100%" }}>
                                <Autocomplete
                                    value={list_stations.length > 0 ? {
                                        codigo_estacao: data_access.estacao ? data_access.estacao : 'Selecione',
                                        nome: list_stations.filter((el) => el.codigo_estacao === data_access.estacao)[0]?.nome || '',
                                        localidade: {
                                            municipio: list_stations.filter((el) => el.codigo_estacao === data_access.estacao)[0]?.localidade.municipio || '',
                                            sigla_estado: list_stations.filter((el) => el.codigo_estacao === data_access.estacao)[0]?.localidade.sigla_estado || ''
                                        }
                                    } : {codigo_estacao: '', nome: '', localidade: {municipio:'', sigla_estado: ''}}}
                                    options={list_stations}
                                    getOptionLabel={(option) => {
                                        return `${option.codigo_estacao} ${option.nome ? option.nome+'/': (option.codigo_estacao && !option.nome) ? '- ESTAÇÃO DELETADA': ''}${option.localidade?.municipio ? option.localidade?.municipio+'-' : ''}${option.localidade?.sigla_estado}`
                                    }
                                    }
                                    id="estacao"
                                    autoComplete
                                    includeInputInList
                                    renderInput={(params) => <TextField {...params} label="Estação  *" margin="normal" />}
                                    onChange={async (e) => {
                                        const loc = e.target.innerText ? e.target.innerText.split(' ')[0] : ""
                                        const data = await list_stations.filter((el) => el.codigo_estacao === loc)
                                        if (data.length > 0) {
                                            handle_data_access("estacao", `${data[0].codigo_estacao}`)
                                        } else {
                                            handle_data_access("estacao", '')
                                        }
                                    }}
                                />
                            </div>
                        }


                        {type_access === 'VPN' &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("descricao", e.target.value)
                                        }}
                                        id="descricao"
                                        label="Título"
                                        value={data_access.descricao}
                                    />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("referencia_estacao", e.target.value)
                                        }}
                                        id="referencia_estacao"
                                        label="Referência da Estação"
                                        value={data_access.referencia_estacao}
                                    />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-label">Tipo de VPN</InputLabel>
                                        <Select
                                            defaultValue={data_access.tipo_vpn}
                                            labelId="select-label"
                                            id="select-tipo-vpn"
                                            // value={type_filter_access}
                                            onChange={e => {
                                                handle_data_access("tipo_vpn", e.target.value)
                                            }}
                                        >
                                            <MenuItem value="PPTP">PPTP</MenuItem>
                                            <MenuItem value="L2TP">L2TP</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("chave_ipsec", e.target.value)
                                        }}
                                        id="chave_ipsec"
                                        label="Chave IPSec"
                                        value={data_access.chave_ipsec}

                                    />
                                </div>
                            </>
                        }

                        {(type_access === 'Ativo de Rede' || type_access === 'VPN' || type_access === 'Ferramenta' || type_access === 'Conta' || type_access === 'Servidor') &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_access("ip_dominio_link", e.target.value)
                                    }}
                                    id="ip_dominio_link"
                                    label={(type_access === 'VPN' || type_access === 'Servidor' || type_access === 'Ativo de Rede') ? 'IP' : type_access === 'Conta' ? 'URL' : 'IP/Domínio'}
                                    required={true}
                                    value={data_access.ip_dominio_link}
                                />
                            </div>
                        }

                        {(type_access === 'Ativo de Rede' || type_access === 'Servidor') &&
                            <div style={{ width: "100%" }}>
                                <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                    <InputLabel htmlFor="select-label">Tipo de Acesso</InputLabel>
                                    <Select
                                        defaultValue={data_access.tipo_acesso ? data_access.tipo_acesso : ""}
                                        labelId="select-label"
                                        id="select-tipo-acesso"
                                        // value={type_filter_access}
                                        onChange={e => {
                                            handle_data_access("tipo_acesso", e.target.value)
                                        }}
                                    >
                                        <MenuItem value="telnet">Telnet</MenuItem>
                                        <MenuItem value="ssh">SSH</MenuItem>
                                        <MenuItem value="winbox">winbox</MenuItem>
                                        <MenuItem value="web">Web</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        }


                        {type_access === 'Ativo de Rede' &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-label">Equipamento&nbsp;&nbsp;*</InputLabel>
                                        <Select
                                            defaultValue={data_access.equipamento}
                                            labelId="select-label"
                                            id="select-equipamento"
                                            // value={type_filter_access}
                                            onChange={e => {
                                                handle_data_access("equipamento", e.target.value)
                                            }}
                                        >
                                            <MenuItem value="Roteador">Roteador</MenuItem>
                                            <MenuItem value="Retificador">Retificador</MenuItem>
                                            <MenuItem value="Switch">Switch</MenuItem>
                                            <MenuItem value="Firewall">Firewall</MenuItem>
                                            <MenuItem value="OLT">OLT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-label">Fabricante&nbsp;&nbsp;*</InputLabel>
                                        <Select
                                            defaultValue={data_access.fabricante}
                                            labelId="select-label"
                                            id="select-Fabricante"
                                            // value={type_filter_access}
                                            onChange={e => {
                                                handle_data_access("fabricante", e.target.value)
                                            }}
                                        >
                                            <MenuItem value="Huawei">Huawei</MenuItem>
                                            <MenuItem value="Cisco">Cisco</MenuItem>
                                            <MenuItem value="Datacom">Datacom</MenuItem>
                                            <MenuItem value="Mikrotik">Mikrotik</MenuItem>
                                            <MenuItem value="Juniper">Juniper</MenuItem>
                                            <MenuItem value="Intelbras">Intelbras</MenuItem>
                                            <MenuItem value="Fiberhome">Fiberhome</MenuItem>
                                            <MenuItem value="Raisecom">Raisecom</MenuItem>
                                            <MenuItem value="A10">A10</MenuItem>
                                            <MenuItem value="Hillstone">Hillstone</MenuItem>
                                            <MenuItem value="Extreme">Extreme</MenuItem>
                                            <MenuItem value="Outro">Outro</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("modelo", e.target.value)
                                        }}
                                        id="modelo"
                                        label="Modelo"
                                        value={data_access.modelo}
                                    />
                                </div>
                            </>
                        }


                        {(type_access === 'Ferramenta' || type_access === 'Conta') &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_access("descricao", e.target.value)
                                    }}
                                    id="descricao"
                                    label="Serviço"
                                    value={data_access.descricao}
                                />
                            </div>
                        }


                        {(type_access === 'Ativo de Rede' || type_access === 'Servidor') &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_access("descricao", e.target.value)
                                    }}
                                    id="descricao"
                                    label={type_access === 'Ativo de Rede' ? 'Hostname' : 'Descrição'}
                                    required={true}
                                    value={data_access.descricao}
                                />
                            </div>
                        }


                        {(type_access === 'Ativo de Rede' || type_access === 'Ferramenta' || type_access === 'Servidor') &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    type="number"
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_access("porta", e.target.value)
                                    }}
                                    id="porta"
                                    label={type_access === 'Servidor' ? 'Porta SSH (Ex: 23)' : 'Porta (Ex: 8080)'}
                                    value={data_access.porta}
                                />
                            </div>
                        }

                        {(type_access === 'Ativo de Rede' || type_access === 'VPN' || type_access === 'Ferramenta' || type_access === 'Conta' || type_access === 'Servidor') &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("usuario_intec", e.target.value)
                                        }}
                                        id="usuario_intec"
                                        label="Usuário Intec"
                                        value={data_access.usuario_intec}
                                    />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("senha_intec", e.target.value)
                                        }}
                                        id="senha_intec"
                                        label="Senha Intec"
                                        value={data_access.senha_intec}
                                    />
                                </div>
                            </>
                        }

                        {(type_access === 'Servidor' || type_access === 'Ativo de Rede') &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_access("senha_root", e.target.value)
                                    }}
                                    id="senha_root"
                                    label="Senha root"
                                    value={data_access.senha_root}
                                />
                            </div>
                        }


                        {(type_access === 'Ativo de Rede' || type_access === 'VPN' || type_access === 'Ferramenta' || type_access === 'Conta' || type_access === 'Servidor') &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("usuario_cliente", e.target.value)
                                        }}
                                        id="usuario_cliente"
                                        label="Usuário cliente"
                                        value={data_access.usuario_cliente}
                                    />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("senha_cliente", e.target.value)
                                        }}
                                        id="senha_cliente"
                                        label="Senha cliente"
                                        value={data_access.senha_cliente}
                                    />
                                </div>
                            </>
                        }


                        {(type_access === 'Ativo de Rede' || type_access === 'VPN' || type_access === 'Conta' || type_access === 'Servidor' || type_access === 'Ferramenta') &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_access("observacoes", e.target.value)
                                    }}
                                    id="Observações"
                                    label="Observações"
                                    value={data_access.observacoes}
                                />
                            </div>
                        }


                        <div style={{ width: "100%" }}>
                            <Button
                                className={styles.btn_submit}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={submit_is_disabled}
                                onClick={async () => {

                                    let filters;

                                    switch (data_access.tipo) {
                                        case 'Ativo de Rede':
                                            filters = {
                                                situacao: "Situação",
                                                // localidade: "Localidade",
                                                estacao: "Estação",
                                                ip_dominio_link: "IP",
                                                // tipo_acesso: "Tipo de Acesso",
                                                equipamento: 'Equipamento',
                                                fabricante: 'Fabricante',
                                                descricao: "Hostname",
                                            }
                                            break;

                                        case 'VPN':
                                            filters = {
                                                situacao: "Situação",
                                                // localidade: "Localidade",
                                                descricao: "Título",
                                                ip_dominio_link: "IP"
                                            }
                                            break;

                                        case 'Conta':
                                            filters = {
                                                situacao: "Situação",
                                                // localidade: "Localidade",
                                                ip_dominio_link: "URL",
                                                descricao: "Serviço"
                                            }
                                            break;

                                        case 'Servidor':
                                            filters = {
                                                situacao: "Situação",
                                                // localidade: "Localidade",
                                                estacao: "Estação",
                                                ip_dominio_link: "IP",
                                                // tipo_acesso: "Tipo de Acesso",
                                                descricao: "Descrição"
                                            }
                                            break;

                                        case 'Ferramenta':
                                            filters = {
                                                situacao: "Situação",
                                                // localidade: "Localidade",
                                                ip_dominio_link: "IP/Domínio",
                                                descricao: "Serviço"
                                            }
                                            break;

                                        default:
                                            break;
                                    }

                                    for (let f in Object.values(filters)) {
                                        let filter = Object.keys(filters)[f]
                                        if (data_access[filter] === undefined || data_access[filter] === '') {
                                            return alert(`Campo obrigatório: ${filters[filter].toUpperCase()}`)
                                        }
                                    }

                                    try {
                                        const resp = await api_bd.put(`/customers/editaccess/${data_client._id}/${data_access._id}`, data_access)
                                        props.callbackfn()
                                        set_update_client_data(resp.data)
                                        // alert(`Dados inseridos com sucesso!\nACESSO\nLocalidade: ${data_access.localidade}\nDescrição: ${data_access.descricao}\nCliente: ${data_client.nome}`)
                                    } catch (error) {
                                        if (error.message.indexOf('code 400') > -1) {
                                            alert("Verifique os dados e tente novamente.")
                                        }
                                    }
                                }}
                            >
                                Salvar
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

export default FormEditAccess;

//Schema Acesso
//{"localidade":"Capim","situacao":"Ativo","tipo":"Ativo de Rede","equipamento":"roteador","fabricante":"intelbras","modelo":"NMS3000","descricao":"descTESTE","ip_dominio_link":"user.com","porta":"123","usuario_intec":"userintec","senha_intec":"senhaintec","senha_root":"senharoot","usuario_cliente":"userclient","senha_cliente":"senhaclient","observacoes":"observaaaaa"}