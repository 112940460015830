import React, { useEffect, useState } from 'react';

function ContadorTempoTratativas(props) {
    let t_execucao = 0

    const [tempo_execucao, setTempo_execucao] = useState('')

    const calcMs = (_time_inicio, _time_fim) => {
        const date1 = Date.parse(_time_inicio)
        const date2 = Date.parse(_time_fim)
        const diffTime = date2 - date1
        return (diffTime)
    }
    const msToTime = (ms) => {
        let x = ms / 1000
        let secs = x % 60
        x /= 60
        let mins = x % 60
        x /= 60
        let hrs = x % 24
        x /= 24
        let days = x

        // console.log(days)

        // return Math.floor(days)-1 + 'd:'  + hrs<10 ? '0'+hrs : hrs + ':' + mins + ':' + secs
        return (`${days >= 1 ? Math.floor(days) + ' dia'+ (Math.floor(days) >= 2 ? 's': ' ') : ''} ${hrs < 10 ? '0' + Math.floor(hrs) : Math.floor(hrs)}:${mins < 10 ? '0' + Math.floor(mins) : Math.floor(mins)}:${secs < 10 ? '0' + Math.floor(secs) : Math.floor(secs)}`)
    }

    useEffect(() => {
        if (props.dados && props.dados.length > 0) {
            // console.log('props.dados', props.dados)
            // for (let key in props.dados) {
            //     if (props.dados[key].fim !== null) {
            //         t_execucao += calcMs(props.dados[key].inicio, props.dados[key].fim)
            //     }
            // }

            const totalTime = props.dados.reduce((total, num) => {
                //console.log(num)
                return total + calcMs(num.inicio, num.fim);
            }, 0);

            setTempo_execucao(msToTime(totalTime))
        }else{
            setTempo_execucao(0)
        }
    }, [props])

    return (
        <>
            {tempo_execucao === '' ? 'Calculando....' : tempo_execucao === 0 ? '00:00:00' : tempo_execucao}
            {/* {props.dados.length === 0 || t_execucao === 0 ? '00:00:00' : ''}
            {props.dados.length > 0 && t_execucao > 0 && msToTime(t_execucao)} */}
        </>
    )
}

export default ContadorTempoTratativas;